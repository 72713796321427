require(['componentLoader'], function(helper) {
  helper.requireDependencies();
});

define(['common'], function() {

  var elemis = function() {
    var self = this;
    function init() {
    }

    self.init = init;
  };

  var elemisSite = new elemis();
  elemisSite.init();
  return elemisSite;
});
